import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import MasterService from "@/core/services/master.service";

export default {
  components: {
    // Dropdown1
  },
  
  computed: {
    ...mapGetters([
      "currentUser",
      "currentYear",
      "currentModule",
    ]),
    routeName(){
      return this.$route.name;
    },
    kodeModul(){
      return this.$route.meta.isModule;
    }
  },

  props: ['authMode'],
  
  data() {
    return {
      // :core
      dataUser: {},
      searchMain: {},
      role_kode : null,
      user_id : null,
      user_id_induk :null,
      // :end core

      // :last 10 transaction 
      tContents: [],
      tContentsTotal: 1,
      tContentsHeader: [
        { text: 'No Surat Jalan', align: 'start', value: 'no_surat_jalan', sortable: false},
        { text: 'Tanggal', align: 'start', value: 'date', sortable: false},
        { text: 'Tgl Pengiriman', align: 'start', value: 'date', sortable: false},
        { text: 'Pengiriman', align: 'start', value: 'jenis_pengiriman', sortable: false},
        { text: 'Shipper', align: 'start', value: 'shipper_name', sortable: false},
        { text: 'Customer', align: 'start', value: 'customer_name', sortable: false},
        { text: 'Total koli', align: 'right', value: 'total_coly', sortable: false},
        { text: 'DN', align: 'right', value: 'items', sortable: false},
        { text: 'No Mobile', align: 'right', value: 'no_mobile', sortable: false},
        { text: 'Biaya vendor', align: 'right', value: 'harga_vendor', sortable: false},
        { text: 'Biaya lainnya', align: 'right', value: 'biaya_lainnya', sortable: false},
        { text: 'Status', align: 'right', value: 'status_tracking', sortable: false},
        { text: 'Total Pembayaran', align: 'right', value: 'total_price', sortable: false},
        
      ],

      tContentsOptions: {},
      tContentsLoading: false,
      tContentsDisabled: false,
      tContentsPaginations: [5,10,15,25,50,100],
      tContentsSearch: {},
      permission : [],
      permissionarr : [],
      loadingShipper: false,
      dataShipper: [],
      dataJenisPengiriman: [],
      loadingJenisPengiriman: false,
      // :end last 10 transaction

      // :additional
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Laporan", route: "/" },
      { title: "Surat Jalan" }
    ]),

    this.dataUser = this.currentUser;
    this.searchMain.tahun = this.currentYear;
    this.searchMain.admin_id = Number(this.currentUser.id);
    this.role_kode = this.currentUser.role_kode;
    this.user_id = Number(this.currentUser.id);
    this.searchMain.branch_id = Number(this.currentUser.branch_id);

    Date.prototype.addDays = function(days) {
      var date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    }
  
    var today  = new Date();
    this.dateNow = today.toLocaleDateString("en-US");
    var startdate = today.addDays(-30);
    this.tContentsSearch.date_start = new Date(startdate).toISOString().substr(0, 10); 
    this.tContentsSearch.date_end = new  Date().toISOString().substr(0, 10); 
    
    // alert(this.currentUser.branch_id);

    this.currentModule.forEach(e => {
      if(this.kodeModul == e.module_kode){
        this.permissionarr.push(e.permissions.map(m => m.kode));
      }
    });

    var result = [];
    this.permissionarr.forEach(function(item)
    {
      item.forEach(function (value)
      {
          result.push(value);
      });
    });
    
    this.permission = result;
    // alert(this.permission);
    
    this.loadMainContent();
    this.loadBranch();
    this.loadShipper();
    this.loadJenisPengiriman();
  },

  watch: {
    tContentsOptions: {
      handler () {
        this.loadMainContent();
      },
      deep: true,
    },
    authMode: function() {
      this.loadMainContent();
    }
  },

  methods: {
    getMainList() {
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.tContentsOptions;
        
        let dataSort = {};
        let isSort = sortDesc.length;
        if(isSort > 0){
          for(let i=0; i<isSort; i++){
            let item1 = sortBy[i];
            let item2 = sortDesc[i];
            
            if(item2 == true){
              dataSort[item1] = "DESC";
            }else{
              dataSort[item1] = "ASC";
            } 
          }
        }

        dataSort["id"] = "DESC";
        console.log(this.searchMain);
        var search = {...this.tContentsSearch};
        var query = { 
          start: (page - 1) * itemsPerPage,
          length: itemsPerPage,
          main: this.searchMain,
          search: search
        }
        ApiService.post("surat_jalan/list", query, 1).then(({ data }) => {
          resolve(data)
        }).catch(({ response }) => {
          reject(response);
        })

      })
    },

    submitSearchTContents(){
      this.tContentsOptions.page = 1;
      this.loadMainContent();
    },

    // :loader
    loadMainContent(){
      this.tContents = [];
      this.tContentsTotal = 0;
      this.tContentsLoading = true;
      this.getMainList().then(data => {
        this.tContentsLoading = false;
        if(data.detail){
          this.tContents = data.detail
        }else{
          this.tContents = []
        }
        this.tContentsTotal = data.count
      }).catch((response)=>{
        this.tContents = []
        this.tContentsTotal = 0;
        this.tContentsLoading = false;
        this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
      })
    },
    loadShipper(){
      this.loadingShipper = true;
      MasterService.loadMaster("dropdown", "customer").then(({ data })=>{
        this.loadingShipper = false;
        this.dataShipper = data.data;
      }).catch((response)=>{
        this.loadingShipper = false;
        this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
      });
    },
    loadJenisPengiriman(){
      this.loadingJenisPengiriman = true;
      MasterService.loadMaster("dropdown", "jenis_pengiriman").then(({ data }) => {
        this.loadingJenisPengiriman = false;
        this.dataJenisPengiriman = data.data;
      }).catch((response)=>{
        this.loadingJenisPengiriman = false;
        this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
      });
    },
    loadBranch(){
      this.loadingBranch = true;
      MasterService.loadMaster("dropdown", "branch").then(({ data }) => {
        this.loadingBranch = false;
        this.dataBranch = data.data;
      }).catch((response)=>{
        this.loadingBranch = false;
        this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
      });
    },
    exportExcel(){
      
      ApiService.post("surat_jalan/exportExcelSuratJalan", this.tContentsSearch).then(({ data }) => {
        var doc = data.detail;
        var fileLink = document.createElement('a');
        fileLink.href = doc.file;
        fileLink.setAttribute('download', doc.name);
        document.body.appendChild(fileLink);

        fileLink.click();
      }).catch(({ response }) => {
        this.$swal("Failed", this.$helpers.getTextAlert(response), "error");
      })
    },
    
    // :end loader
  },
  
};